<template>
  <div class="intro">
    <div class="info-container">
      <h1>Seguros Front Office</h1>
      <h3>Bienvenido, Seleccione el Sponsor</h3>
       
      <br />
      <v-btn color="primary" @click="$router.push('cotizador')">
        Contratar ahora
      </v-btn>
    </div>
  </div>
</template>

<script>
//$router.push('cotizador')
import ShieldService from "../services/shield.service";
export default {
  props: ["userData"],
  name: "Intro",
  data: () => ({
    items: [],
    sponsor: "",
  }),
  mounted() {
    this.getSponsor()
  },
  methods: {
    async getSponsor(){
      const res = await ShieldService.getSponsors();
      this.items = res.sponsors;
    },

   async test(){
    console.log(this.sponsor)
   },

     async getPlans(){
       console.log(this.sponsor)
       const res = await ShieldService.getPlans(this.sponsor);
       console.log(res)
     }
  }
};
</script>

<style lang="scss">
.intro {
  min-height: 500px;
  background-image: url("~@/assets/Intro.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  .info-container {
    background: rgba(0, 38, 62, 0.4);
    color: white;
    max-width: 500px;
    // max-height: 300px;
    padding: 2rem 3rem;
    line-height: 1.1;

    h1 {
      font-size: 2.5rem;
    }
    h3 {
      font-weight: normal;
      font-size: 2rem;
    }
  }
}
</style>