import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_CARRO_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async createStep(values, step, version, id) {
    try {
      var carroRes;
      var data = {};
      var lenght = 0;

      //Para el primer step del formulario
      if (step == 1) {
        //Para el primera interacción
        if (version == 1) {
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            lenght: 1,
          };

          carroRes = await axios.post(
            `${VUE_APP_CARRO_URL}/step/create`,
            params
          );
        }
        //Para las demás interacciones interacción
        else {
          values.forEach((element) => {
            if (element != null) {
              lenght = lenght + 1;
            }
          });
          data = {
            name: values[0],
            email: values[1],
            phoneNumber: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            id: id,
            lenght: lenght,
          };

          carroRes = await axios.put(
            `${VUE_APP_CARRO_URL}/step/update`,
            params
          );
        }
      }
      //Para el segundo step del formulario
      else if (step == 2) {
        const params = {
          sponsor: VUE_APP_SPONSOR,
          steptwo: values,
          id: id,
          lenght: 4,
        };

        carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      }
      //Para el tercer step del formulario
      else if (step == 3) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          name: values[0],
          surname: values[1],
          serialNumber: values[2],
          rut: values[3],
          region: values[4],
          commune: values[5],
          address: values[6],
          email: values[7],
          phoneNumber: values[8],
          species: values[9],
          breed: values[10],
          petName: values[11],
          petAge: values[12],
          sex: values[13],
          hasMicrochip: values[14],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght,
        };

        carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      }

      return carroRes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el Step",
      };
    }
  }


  static async createInsurance(person, pet, plan, stepId) {
    console.log(plan[0]._id)
    person.region = person.region.id;
    person.commune = person.commune.id;
    pet.sex = pet.sex.id;
    pet.species = pet.species.id;
    const jsonData = { person, pet, planId: plan[0]._id, stepId: stepId };
    console.log("shield Service")
    console.log(jsonData)
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/gallagher`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }

  static async getSponsors() {
    try {
      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/marketing/getType`);
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPlans(sponsor) {
    try {
      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/marketing/getPlan?sponsor=${sponsor}`);
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }
}
