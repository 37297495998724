<template>
  <v-app>
    <v-app-bar app color="#9F1C64" light elevation="0" height="75">
      <router-link :to="{ path: '/' }">
        <div class="d-flex align-center">
          <v-img
            contain
            max-height="125"
            :src="LogoHeader"
            max-width="175"
            style="margin-left: 2rem"
          />
        </div>
      </router-link>

      <v-spacer></v-spacer>

    




    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import LogoHeader from "@/assets/images/logo_sb.png";

export default {
  data: () => ({
    LogoHeader,
  }),
};
</script>