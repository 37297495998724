<template>
  <div style="background-color: #fafafa">
    <v-container>
      <div class="coverage">
        <h1>Cobertura</h1>

        <div class="coverage-container">
          <v-layout row wrap>
            <v-card max-width="325">
              <v-img :src="Coverage1" height="250px"></v-img>
              <v-card-title>
                Urgencia Veterinaria:
              </v-card-title>
              <v-card-text>
                Hasta 80% de reembolso en
                gastos médicos, hasta UF250
                de tope, hasta 3 eventos.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Libre elección</span>
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage2" height="250px"></v-img>
              <v-card-title> Vacuna Antirrábica: </v-card-title>
              <v-card-text>
                UF 0.5 en reembolso de
                vacuna antirrábica.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Libre elección</span>
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage3" height="250px"></v-img>
              <v-card-title>
                Consultas Médicas:
              </v-card-title>
              <v-card-text>
                Hasta UF 3 de reembolso en
                consultas médicas preventivas
                para tu mascota.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Libre elección</span>
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage4" height="250px"></v-img>
              <v-card-title>
                Responsabilidad civil extracontractual:
              </v-card-title>
              <v-card-text>
                Porque ellos también necesitan que los cuiden como se merecen,
                por eso siempre estamos contigo para ayudarte en los cuidados
                que necesita tu mascota.
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage5" height="250px"></v-img>
              <v-card-title> Telemedicina Veterinaria: </v-card-title>
              <v-card-text>
                Orientación médica veterinaria a través de nuestro número telefónico, con atención 24 horas. 
                Tu mascota estará amparada al contar con la atención directa de un veterinario.
              </v-card-text>
              <v-card-text>
                – Solución a: Molestias, síntomas y enfermedades de tu mascota.
              </v-card-text>
              <hr class="mx-4" color="#0075bc" size="1" />
              <v-card-text>
                <span style="font-weight: bold">Límite de atención:</span>
                Hasta 6 eventos al año
              </v-card-text>
            </v-card>

            <v-card max-width="325">
              <v-img :src="Coverage6" height="250px"></v-img>
              <v-card-title> Asistencia Legal telefónica: </v-card-title>
              <v-card-text>
                Otorgará orientación legal sobre
                tenencia responsable de mascotas,
                Ordenanzas comunales, facultades de
                autoridades municipales en relación con
                la mascota y/o responsabilidades de
                veterinarios por daños provocados a tu
                mascota.
              </v-card-text>
            </v-card>
          </v-layout>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Coverage1 from "@/assets/images/urgencia.webp";
import Coverage2 from "@/assets/images/antirrabica.jpeg";
import Coverage3 from "@/assets/images/Coverage3.png";
import Coverage4 from "@/assets/images/Coverage4.png";
import Coverage5 from "@/assets/images/Coverage1.png";
import Coverage6 from "@/assets/images/asistencia-telefonica.webp";

export default {
  name: "Coverage",
  data: () => ({
    Coverage1,
    Coverage2,
    Coverage3,
    Coverage4,
    Coverage5,
    Coverage6,
  }),
};
</script>

<style lang="scss">
.coverage {
  padding: 4rem 0;

  h1 {
    text-align: center;
    font-size: 2.5rem;
  }

  .coverage-container {
    .layout.wrap {
      justify-content: space-around;
    }

    .v-card {
      margin: 1rem;
    }

    .v-card__title {
      font-weight: bold;
      word-break: unset;
      line-height: 1.2;
    }

    .v-card__text {
      color: black !important;
      line-height: 1.2;
    }
  }
}
</style>